<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props: activatorProps }">
      <v-container>
        <v-row style="height: 5vh">
          
        </v-row>
        <v-row justify="center">
          <v-col cols="8">

          
        <v-card :title="inventory.name" flat variant="outlined">

          <v-form v-model="valid">
            <v-container>
              <v-row justify="center" align="center">
                <v-spacer cols="4" md="4">
  
                </v-spacer>
                <v-col cols="4" md="4" style="align-items: center;">
                  <v-img :width="300" aspect-ratio="16/9" cover :src="inventory.s3_link"></v-img>
                </v-col>
                <v-spacer cols="4" md="4">
  
                </v-spacer>
              </v-row>
  
              <v-row justify="center" align="center">
                <v-spacer cols="4" md="4">
  
                </v-spacer>
                <v-col cols="4" md="4">
  
                  <v-file-input v-model="picture" label="File input" @change="onFileChange"></v-file-input>
                </v-col>
                <v-spacer cols="4" md="4">
  
                </v-spacer>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" md="12">
                  <v-text-field  density="compact" hide-details class="pa-2 ma-1" v-model="inventory.name" :rules="nameRules" label="Name" required></v-text-field>
                </v-col>
  
                <v-col cols="12" md="12">
                  <v-text-field  density="compact" clearable hide-details class="pa-2 ma-1" v-model="inventory.price" label="Price" required></v-text-field>
                </v-col>
  
                <v-col cols="12" md="12">
                  <v-text-field  density="compact" clearable hide-details class="pa-2 ma-1" v-model="inventory.cost" label="Cost"></v-text-field>
                </v-col>
  
                <v-col cols="12" md="12">
                  <v-text-field  density="compact" class="pa-2 ma-1" v-model="inventory.quantity" label="Quantity" hide-details></v-text-field>
                </v-col>
      
                <v-col cols="12" md="12">
                  <v-text-field  density="compact" class="pa-2 ma-1" v-model="inventory.quantity_to_alert" label="Quantity To Alert" hide-details></v-text-field>
                </v-col>
  
  
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
        </v-row>
      </v-container>



      <div style="display:flex; flex-direction:row; justify-content:center">
        <div style="margin: 0px 20px 0px 20px">
          <v-btn v-bind="activatorProps" @click="submit()" color="surface-variant" text="Update" variant="flat"></v-btn>

        </div>
        <div style="margin:0px 20px 0px 20px">
          <v-btn v-bind="activatorProps" @click="delete_inventory()" color="red" text="Delete" variant="flat"></v-btn>
        </div>
      </div>





    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Update">
        <v-card-text>
          Data Updated Successfully
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Close" @click="isActive.value = false; router.push('/inventory')"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const inventory = ref({})
const picture = ref()

const route = useRoute()
const router = useRouter()
const inventoryId = computed(() => route.params.id)

let token = localStorage.getItem('token');


function delete_inventory() {
  
  axios.patch(`/inventory/${inventory.value.id}?to_delete=true`,
    inventory.value, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )

}



function getInventory() {
  axios.get(`/inventory/${inventoryId.value}`,
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      inventory.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    })
  
}

function onFileChange(e) {
  const file = e.target.files[0];

  inventory.value.s3_link = URL.createObjectURL(file);

  let inventory_id = inventory.value.id

  var formData = new FormData();

  formData.append("file", picture.value);
  axios.post(`/inventory/${inventory_id}/image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'token': token,
    }}
  )



}

function submit() {
  console.log(inventory.value)
  for (let key in inventory.value){

    if(inventory.value[key] == ""){
      inventory.value[key] = null
    }
  }
  axios.patch(`/inventory/${inventory.value.id}`,
    inventory.value, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )
}

onMounted(() => {
  getInventory()

})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>