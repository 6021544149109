<template>


      <v-card title="Customer" flat>

        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-item>
                          <v-card-title class="text-wrap">{{ customer.name }}</v-card-title>
                        </v-card-item>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-card class="mx-auto">
                                <v-container class="pa-0">
                                  <v-row align="center" style="height: 8vh">
                                    <v-col cols="3" class="pa-1">
                                      <v-icon icon="mdi-phone" />
                                    </v-col>
                                    <v-col cols="9" class="pa-0" style="text-align: left;">

                                      <v-card-text prepend-icon="mdi-phone">{{customer.phone_number}}</v-card-text>
                                    </v-col>
                                  </v-row>
                                </v-container>
                                
                                
                              </v-card>
                            </v-col>
                            <v-col cols="12" >
                              <v-card class="mx-auto">

                                <v-container class="pa-0">
                                  <v-row align="center" style="height: 8vh">
                                    <v-col cols="3" class="pa-1">
                                      <v-icon icon="mdi-email" />
                                    </v-col>
                                    <v-col cols="9" class="pa-0" style="text-align: left;">

                                      <v-card-text class="text-wrap" prepend-icon="mdi-phone">{{customer.email}}</v-card-text>
                                    </v-col>
                                  </v-row>
                                </v-container>


                              </v-card>
                            </v-col>


                          </v-row>
                          <v-row justify="space-around">
                            <v-col cols="12" md="4">

                              <v-btn :to="{ name: 'customer-individual-edit', params: { customer_id: customerId } }" color="surface-variant" text="Update"
                                variant="flat"></v-btn>
                            </v-col>

                          </v-row>
                        </v-container>
                      </v-card>

                    </v-col>




                  </v-row>



                </v-container>
              </v-col>
              <v-col>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-card>
                        <v-card-item>
                          <v-card-title>Previous Orders</v-card-title>
                        </v-card-item>
                        <v-data-table class="ma-2 pa-2" :headers="headers" :items="orders" :search="search"
                          @click:row="handleClick"></v-data-table>

                      </v-card>


                    </v-col>

                  </v-row>
                </v-container>
              </v-col>
            </v-row>



          </v-container>
        </v-form>

      </v-card>


</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const customer = ref({})
const orders = ref([])

const route = useRoute()
const router = useRouter()
const customerId = computed(() => route.params.id)

const search = ref()
const headers = ref([])


let token = localStorage.getItem('token');

const valid = ref()
const nameRules = ref([])
const emailRules = ref([])
const PhoneNumberRules = ref([])


function handleClick(event, row) {
  // console.log("Clicked item: ", row.item)
  // console.log(row.item.id)
  router.push(`/customer/${customerId.value}/order/${row.item.id}`)
}


function init() {

  valid.value = false

  nameRules.value = [
    value => {
      if (value) return true

      return 'Name is required.'
    }
  ]

  emailRules.value = [
    value => {
      if (/.+@.+\..+/.test(value)) return true
      if (value == "") return true
      return 'E-mail must be valid.'
    },
  ]

  PhoneNumberRules.value = [
    value => {
      if (value) return true

      return 'Phone Number is requred.'
    },
    value => {
      if (value?.length > 8) return true

      return 'Phone Number must be more than 8 characters.'
    },
  ]


  search.value = ''
  headers.value = [
    {
      align: 'start',
      key: 'created_at',
      sortable: true,
      title: 'Order Date',
    },
    { key: 'id', title: 'Order id' },
    { key: 'products', title: '# Products' },
    { key: 'discounted_price', title: 'Total Amount' },

  ]


}

function getCustomer() {
  axios.get(`/customer/${customerId.value}`,
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      customer.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    })
}


function getOrders() {
  axios.get(`/order?customer_id=${customerId.value}`,
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      orders.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    })
}

onMounted(() => {
  getCustomer()
  getOrders()
  init()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
