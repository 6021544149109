<template>
  <v-card
    title="New Customers"
    flat
  >
  
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>
          </v-col>
          
          <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="phoneNumber"
            :rules="PhoneNumberRules"
            label="Phone Number"
            required
          ></v-text-field>
        </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              hide-details
            ></v-text-field>
          </v-col>


        </v-row>
      </v-container>
    </v-form>
    <v-btn style="margin-bottom: 10px;" @click="submit()">
      Create
    </v-btn>
  </v-card>

</template>


<script setup>
import { ref , onMounted} from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()

const name = ref()
const valid = ref()
const nameRules = ref([])
const email = ref()
const emailRules = ref([])
const phoneNumber = ref()
const PhoneNumberRules = ref([])

function init(){

  valid.value = false
  name.value = null

  nameRules.value = [
    value => {
      if (value) return true

      return 'Name is required.'
    }
  ]

  email.value = null
  emailRules.value = [
    value => {
      if (/.+@.+\..+/.test(value)) return true
      if (value == "") return true
      return 'E-mail must be valid.'
    },
  ]

  phoneNumber.value = null
  PhoneNumberRules.value = [
    value => {
      if (value) return true

      return 'Phone Number is requred.'
    },
    value => {
      if (value?.length > 8) return true

      return 'Phone Number must be more than 8 characters.'
    },
  ]

}

function submit(){
  if (valid.value === true){
    let token = localStorage.getItem('token');
    let data = {
      'name':name.value,
      'phone_number':phoneNumber.value,
      'email': email.value,
    }

    axios.post('/customer', 
    data,{ 
    headers: { 
      'token': token,
      } })
      .then((response) => {
    console.log(response)

    if(response.status == 200){
      router.push('/customer')
    }

  })
      .catch((error) => {
        console.error(error);
        // logout()
      })
    
  }
}

onMounted(() => {
  init()

})


</script>

<style>

.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td{
  text-align: left;
}
</style>
../../router