<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props: activatorProps }">


      <v-card title="User" flat>
        <v-form v-model="valid">
          <v-container>
            <v-row no-gutters style="height: 10vh" justify="start">
              <v-col cols="1">
                <v-btn @click="backToPrevPage()" color="gray" prepend-icon="mdi-keyboard-backspace" variant="flat">
                  Back</v-btn>
              </v-col>
            </v-row>

            <v-row justify="space-around">
              <v-col cols="8">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-item>
                          <v-card-title>Customer Info</v-card-title>
                        </v-card-item>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12">
                              <v-text-field v-model="user.email" label="Email" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                              <v-text-field v-model="user.phone_number" label="Phone Number" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                              <v-text-field v-model="user.company_name" label="Company Name" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                              <v-text-field v-model="user.valid_until" label="Valid Until (dd/mm/yyyy)" required></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="space-around">
                            <v-col cols="12" md="4">

                              <v-btn v-bind="activatorProps" @click="submit()" color="surface-variant" text="Update"
                                variant="flat"></v-btn>
                            </v-col>

                          </v-row>
                        </v-container>
                      </v-card>

                    </v-col>




                  </v-row>



                </v-container>
              </v-col>

            </v-row>



          </v-container>
        </v-form>

      </v-card>




    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Update">
        <v-card-text>
          Customer Data Updated Successfully
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Close" @click="isActive.value = false; backToPrevPage()"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const user = ref({})

const route = useRoute()
const router = useRouter()
const userId = computed(() => route.params.id)

let token = localStorage.getItem('token');


function backToPrevPage() {
  router.go(-1)
}


function getUser() {
  axios.get(`/admin/user/${userId.value}`,
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      user.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    })
}

function submit() {

  let data = user.value
  let user_id = user.value.id
  delete data.id
  // console.log(`${data.valid_until.getDay()}/${data.valid_until.getMonth()}/${data.valid_until.getYear()}`)
  axios.patch(`/admin/user/${user_id}`,
    data, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )
}


onMounted(() => {
  getUser()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
