import axios from "axios"

// import {useRouter} from 'vue-router';
// const router = useRouter()

export default function useLogoutFunction(){
    
    let token = localStorage.getItem('token')
    axios.delete('/admin/token', 
    { headers: { 
      'token': token,
      } })
  
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('companyName')
    return true
  
  }