<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props: activatorProps }">


      <v-card title="Customer" flat>
        <v-form v-model="valid">
          <v-container>
            <v-row no-gutters style="height: 10vh" justify="start">
              <v-col cols="1">
                <v-btn @click="backToPrevPage()" color="gray" prepend-icon="mdi-keyboard-backspace" variant="flat">
                  Back</v-btn>
              </v-col>
            </v-row>

            <v-row justify="space-around">
              <v-col cols="5">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-item>
                          <v-card-title>Customer Info</v-card-title>
                        </v-card-item>
                        <v-container>
                          <v-row>
                            <v-text-field v-model="customer.name" :rules="nameRules" label="Name" required></v-text-field>
                          </v-row>
                          <v-row>
                            <v-text-field v-model="customer.phone_number" :rules="PhoneNumberRules" label="Phone Number"
                          required></v-text-field>
                          </v-row>
                          <v-row>
                            <v-text-field v-model="customer.email" :rules="emailRules" label="E-mail" hide-details></v-text-field>
                          </v-row>
                          <v-row justify="space-around">
                            <v-col cols="12" md="4" >
                      
                              <v-btn v-bind="activatorProps" @click="submit()" color="surface-variant" text="Update"
                                variant="flat"></v-btn>
                            </v-col>
        
                          </v-row>
                        </v-container>
                      </v-card>
  
                    </v-col>
                    
      
      
      
                  </v-row>



                </v-container>
              </v-col>
             
            </v-row>
            
            

          </v-container>
        </v-form>

      </v-card>




    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Update">
        <v-card-text>
          Customer Data Updated Successfully
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Close" @click="isActive.value = false; backToPrevPage()"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const customer = ref({})

const route = useRoute()
const router = useRouter()
const customerId = computed(() => route.params.customer_id)

const search = ref()
const headers = ref([])


let token = localStorage.getItem('token');

const valid = ref()
const nameRules = ref([])
const emailRules = ref([])
const PhoneNumberRules = ref([])

function backToPrevPage() {
  router.go(-1)
}


function init() {

  valid.value = false

  nameRules.value = [
    value => {
      if (value) return true

      return 'Name is required.'
    }
  ]

  emailRules.value = [
    value => {
      if (/.+@.+\..+/.test(value)) return true
      if (value == "") return true
      return 'E-mail must be valid.'
    },
  ]

  PhoneNumberRules.value = [
    value => {
      if (value) return true

      return 'Phone Number is requred.'
    },
    value => {
      if (value?.length > 8) return true

      return 'Phone Number must be more than 8 characters.'
    },
  ]


  search.value = ''
  headers.value = [
          {
            align: 'start',
            key: 'created_at',
            sortable: true,
            title: 'Order Date',
          },
          { key: 'id', title: 'Order id' },
          { key: 'products', title: '# Products' },
          { key: 'discounted_price', title: 'Total Amount' },

        ]


}

function getCustomer() {
  axios.get(`/customer/${customerId.value}`,
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      customer.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    })
}

function submit() {
  let data = {
    'name': customer.value.name,
    'phone_number': customer.value.phone_number,
    'email': customer.value.email
  }

  axios.patch(`/customer/${customer.value.id}`,
    data, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )
}


onMounted(() => {
  getCustomer()
  init()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
