<template>
  <v-layout>
    <v-navigation-drawer v-if="$route.meta.hideNavbar == undefined" floating permanent :width="200" class="bg-light-blue-lighten-5">
      <v-list density="compact" nav>
        <v-list-item prepend-icon="mdi-view-dashboard" title="Home" value="home" to="/home"></v-list-item>
        <v-list-item prepend-icon="mdi-basket" title="Order" value="order" to="/order"></v-list-item>
        <v-list-item prepend-icon="mdi-human-male-male" title="Customer" value="customer" to="/customer"></v-list-item>
        <v-list-item prepend-icon="mdi-forum" title="Product" value="product" to="/product"></v-list-item>
        <v-list-item prepend-icon="mdi-plus-box-multiple" title="Addon" value="addon" to="/addon"></v-list-item>
        <v-list-item prepend-icon="mdi-package-variant" title="Inventory" value="inventory" to="/inventory"></v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="changePassword()">
            Change Password
          </v-btn>
        </div>
        <div class="pa-2">
          <v-btn block @click="logout()">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main style="height: 250px">

      <router-view />
    </v-main>

  </v-layout>
  <!--  -->

</template>

<script setup>
import useLogoutFunction from './composables/useLogoutFunction.js'
import { useRouter} from 'vue-router';

const router = useRouter()
function logout() {
  let res = useLogoutFunction()
  if (res == true) {
    router.push('/')
  }

}

function changePassword() {
  router.push('/change-password')
}





</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.full-page {
  height: 100vh;
  flex-grow: 8;
}

.main-screen {
  display: flex;
  flex-direction: row;
}

.nav-bar {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.nav-items {
  display: flex;
  flex-direction: column;
}

.nav-items div {
  border-radius: 25px;
  border: 2px solid #73AD21;
}
</style>
