<template>
  <v-card title="Inventory" flat>
    <template v-slot:text>
      <v-container>
        <v-row no-gutters>
          <v-col>
            <v-sheet class="ma-1">
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
                hide-details single-line></v-text-field>
            </v-sheet>
          </v-col>
          <v-col cols="1">
            <v-sheet class="ma-1">
              <v-btn icon="mdi-plus-box" variant="tonal" to="/inventory/create" color="blue">
              </v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-container>
      <v-row no-gutters>
        <v-col v-for="inventory,index in inventory_display" :key="inventory.id" cols="12" sm="4">
          <v-card class="ma-2 pa-2 bg-light-blue-lighten-5" max-width="250" max-height="300px">

            <v-container>
              <v-row >
                <v-col @click="change_page(inventory.id)" >
                  <v-img :width="250" :height='170' :src="inventory.s3_link"></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="justify-center pa-0" style="font-size:20px">
                  {{ inventory.name }}
                  
                  
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="4" style="pa-0">
                  <v-btn color="red" icon="mdi-minus" variant="plain" @click="change_amount(inventory,'reduce')">

                  </v-btn>
                </v-col>
                
                <v-col v-if="inventory.quantity > inventory.quantity_to_alert" cols="4" align-self="center" style="font-size:20px; color:black" >
                  {{ inventory.quantity }}
                </v-col>
                <v-col v-else cols="4" style="font-size:20px; color:red" align-self="center">
                  {{ inventory.quantity }}
                </v-col>
                
                <v-col cols="4" style="pa-0">
                  <v-btn color="green" icon="mdi-plus" variant="plain" @click="change_amount(inventory,'increase',index)">

                  </v-btn>
                  
                </v-col>
              </v-row>
            </v-container>

          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-card>

</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()
const inventory = ref([])
const search = ref()
let token = localStorage.getItem('token');

function change_page(id){
  router.push(`/inventory/${id}`)
}

const getInventory = async () => {

  

  try {
    let response = await axios.get('/inventory?is_deleted=true&skip=0&limit=100',
      {
        headers: {
          'token': token
        }
      })
    inventory.value = response.data
  } catch (error) {
    console.log(error)
  }

}

const inventory_display = computed(() => {
  if (search.value == null) {
    return inventory.value
  }
  else {
    return inventory.value.filter((item) => item.name.toLowerCase().includes(search.value.toLowerCase()));
  }
}
)

function change_amount(item,method){
  let new_quantity = item.quantity

  if (method == 'reduce'){
    new_quantity = new_quantity - 1
  }
  else if(method == 'increase'){
    new_quantity = new_quantity +1
  }

  item['quantity'] = new_quantity
  console.log(item.quantity > item.quantity_to_alert)
  axios.patch(`/inventory/${item.id}`,
    item, {
    headers: {
      'token': token,
    }
  })
    .then(() => {
  
      getInventory()
    }

    )

}

onMounted(() => {
  getInventory()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
