import 'bootstrap/dist/css/bootstrap.css';
import "core-js/stable";
import { createApp } from "vue";
import axios from 'axios';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import App from './App.vue';
import router from './router';

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'light',
        //
      },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
        },
      },
  })
  

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;  // the FastAPI backend
  
const app = createApp(App)
app.use(vuetify)
app.use(router);
app.mount("#app");
