<template>
  <v-card title="Orders" flat>
    <template v-slot:text>
      <v-container>
        <v-row no-gutters>
          <v-col cols="7" class="ma-2">
            <v-card :variant="outlined" style="margin-bottom:4px">
              <v-card-item>
                <v-card-title>Customer</v-card-title>
              </v-card-item>
              <v-card-item>
                <v-container>
                  <v-row no-gutters class="pa-1 ma-1">
                    <v-autocomplete return-object density='compact' v-model="selected_customer" clearable
                      label="Customer Name (Optional)" :items="customers" item-title="name"></v-autocomplete>
                  </v-row>
                  <v-row v-if="selected_customer != undefined" no-gutters class="pa-1 ma-1">
                    <v-col cols="4">
                      Email: {{ selected_customer.email }}
                      <!-- {{ selected_customer.phone_number }} -->
                    </v-col>
                    <v-col cols="4">
                      Phone Number: {{ selected_customer.phone_number }}
                    </v-col>
                  </v-row>

                </v-container>

              </v-card-item>


            </v-card>





            <v-card :variant="outlined" style="margin-top:4px">
              <v-card-item>
                <v-card-title>Product</v-card-title>
              </v-card-item>
              <v-card-item>
                <v-container>
                  <v-row no-gutters class="pa-2 ma-2">
                    <!-- <v-col cols="12">
                      image
                    </v-col> -->
                    <v-col cols="10">
                      <v-autocomplete density='compact' return-object v-model="selected_product"
                        @option:selected="() => { reset }" clearable label="Product" :items="products"
                        item-title="name"></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-card-text v-if="selected_product">
                        {{ selected_product.price }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pa-2 ma-2">
                    <v-col cols="10">
                      <v-autocomplete density='compact' return-object v-model="selected_addons" clearable label="Addons"
                        :items="getAddons" item-title="name" multiple></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-card-text v-if="selected_addons.length > 0">
                        {{ getAddonsPrice }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters style="display:flex;align-items:center;" class="centered-input pa-2 ma-2">
                    <v-col cols="10">
                      <v-card-text style="font-size:20px">
                        Discount
                      </v-card-text>

                    </v-col>

                    <v-col cols="2">
                      <v-text-field hide-details='auto' v-model="discount" variant="outlined"></v-text-field>
                    </v-col>
                  </v-row>
                  <hr>
                  <v-row no-gutters class="pa-2 ma-2">
                    <v-col cols="10">
                      <v-card-text style="font-size:20px">
                        Total
                      </v-card-text>

                    </v-col>
                    <v-col cols="2">
                      <v-card-text>
                        {{ getFinalPrice }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-btn style="margin-bottom: 10px;" @click="addToCart()">
                        Add To Cart
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

              </v-card-item>


            </v-card>

          </v-col>
          <v-col cols="4" class="ma-2">
            <v-card>
              <v-card-item>
                <v-card-title>Cart</v-card-title>


              </v-card-item>
              <v-container>
                <v-row no-gutters>

                  <v-col v-for="[index, item] of cart_items.entries()" :key="item" cols="12" sm="12">
                    <v-row class="ma-1">
                      <v-col cols="2" sm="2">
                        <v-btn icon="mdi-close-thick" variant="plain" @click="removeFromCart(index)" color="red">
                        </v-btn>
                      </v-col>


                      <v-col cols="6" sm="6" style="text-align: left;">
                        <div>
                          {{ item.product.name }}
                        </div>
                        <div>
                          <div v-for="cart_addon in item.addons" :key="cart_addon"
                            style="text-indent: 15px; color: grey;">
                            {{ cart_addon.name }}

                          </div>
                        </div>

                      </v-col>
                      <v-col cols="4" sm="4" class="d-flex" style="align-items: right; justify-content:center">
                        RM {{ item.discounted_price }}
                      </v-col>

                    </v-row>



                  </v-col>


                </v-row>
                <hr>
                <v-row v-if="cart_items.length != 0">
                  <v-col cols="8" sm="8">
                    Total
                  </v-col>
                  <v-col cols="4" sm="4" class="d-flex" style="align-items: right; justify-content:center">
                    RM {{ totalCartPrice }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-spacer>

                  </v-spacer>
                  <v-col cols="8" sm="8">
                    <v-btn style="margin-bottom: 10px;" @click="completeOrder()">
                      Complete Order
                    </v-btn>
                  </v-col>
                  <v-spacer>

                  </v-spacer>
                </v-row>

              </v-container>

            </v-card>

          </v-col>
          <v-spacer> </v-spacer>
        </v-row>
      </v-container>


    </template>
  </v-card>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

import { useRouter } from 'vue-router';

const router = useRouter()

const customers = ref([])
const products = ref([])
const addons = ref([])
const discount = ref()

const selected_customer = ref()
const selected_product = ref()
const selected_addons = ref([])

const cart_items = ref([])

let final_addon_price = 0
let final_price = 0

let token = localStorage.getItem('token');

function getCustomers() {
  axios.get('/customer?skip=0&limit=100',
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      customers.value = response.data;

    })
    .catch((error) => {
      console.error(error);
    })
}

function reset() {
  console.log('reset')
  selected_addons.value = []
  discount.value = 0
  final_addon_price = 0
}

const completeOrder = async () => {
  let data = {}
  let temp_list = []
  if (selected_customer.value != undefined){
    data['customer_id'] = selected_customer.value.id
  }

  for (let item_index in cart_items.value){
    let temp_dict = {}
    let item = cart_items.value[item_index]
    temp_dict['product_id'] = item.product.id
    temp_dict['price'] = item.price
    temp_dict['discount'] = item.discount
    temp_dict['discounted_price'] = item.discounted_price
    
    temp_dict['addon_ids'] = []
    
    for (let addon_index in item.addons){
      temp_dict['addon_ids'].push(item.addons[addon_index].id)
    }
    
    temp_list.push(temp_dict)
  }
  
  data['data'] = temp_list
  console.log(data)

  try {
    let response = await axios.post('/order',
      data, {
      headers: {
        'token': token
      }
    })
    router.push(`/order/${response.data.id}`)
  } catch (error) {
    console.log(error)
  }



}

function addToCart() {
  let temp_dict = {}

  temp_dict['id'] = String(new Date().getTime()),
  temp_dict['product'] = selected_product.value
  temp_dict['addons'] = []
  for (let addon_index in selected_addons.value) {
    temp_dict['addons'].push(selected_addons.value[addon_index])
    
  }
  
  temp_dict['price'] = final_price + discount.value
  temp_dict['discount'] = discount.value
  temp_dict['discounted_price'] = final_price

  cart_items.value.push(temp_dict)


  selected_product.value = null
  reset()
}

function removeFromCart(item_id) {
  cart_items.value.splice(item_id, 1)
}

const totalCartPrice = computed(() => {
  let final_price = 0
  for (let item_index in cart_items.value) {
    let curr_item = cart_items.value[item_index]
    final_price = final_price + curr_item.discounted_price
  }
  return final_price
})


function getProducts() {
  axios.get('/product?skip=0&limit=100',
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      products.value = response.data;

    })
    .catch((error) => {
      console.error(error);
    })
}

function getAllAddons() {
  axios.get('/addon?skip=0&limit=100',
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      addons.value = response.data;

    })
    .catch((error) => {
      console.error(error);
    })
}

const getAddons = computed(() => {
  if (selected_product.value == undefined) {
    return []
  }
  else if (selected_product.value.addon_ids.length == 0) {
    return []
  }
  else {
    reset()
    return addons.value.filter((item) => selected_product.value.addon_ids.includes(item.id));
  }
}
)

const getAddonsPrice = computed(() => {
  if (selected_addons.value == []) {
    return null
  }
  else {
    final_addon_price = 0

    for (let index in selected_addons.value) {
      final_addon_price = final_addon_price + selected_addons.value[index]['price']

    }
    return final_addon_price
  }
}
)

const getFinalPrice = computed(() => {
  if (selected_product.value == undefined) {
    return null
  }
  else if (selected_addons.value != undefined) {
    final_price = selected_product.value.price + final_addon_price - discount.value
    return final_price
  }
  else {
    final_price = selected_product.value.price + final_addon_price - discount.value
    return final_price

  }


}
)

onMounted(() => {
  getCustomers()
  getProducts()
  getAllAddons()
  discount.value = 0

})


</script>

<style>
.centered-input input {
  text-align: center
}
</style>
