<template>
  <v-card title="Add Ons" flat>
    <template v-slot:text>
      <v-container>
        <v-row no-gutters>
          <v-col >
            <v-sheet class="ma-1">
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details
              single-line></v-text-field>
            </v-sheet>
          </v-col>
          <v-col cols="1">
            <v-sheet class="ma-1">
              <v-btn icon="mdi-plus-box" variant="tonal" to="/addon/create" color="blue">
              </v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-container>
      <v-row no-gutters>
        <v-col v-for="addon in addons_display" :key="addon.id" cols="12" sm="4">

          <v-card class="ma-2 pa-2 bg-light-blue-lighten-5" max-width="344"
            :to="{ name: 'addon-individual', params: { id: addon.id } }">

            <div style="font-size: 20px ">
              
              {{ addon.name }}
              <br>
              Price : {{ addon.price }}
              <br>
              Cost : {{ addon.cost }}
            </div>


          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-card>

</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';



const addons = ref([])
const search = ref()

let token = localStorage.getItem('token');

const getAddons = async () => {

  try {
    let response = await axios.get('/addon?skip=0&limit=100',
  {
    headers: {
      'token': token
    }
  })
  addons.value = response.data
  } catch (error) {
    console.log(error)
  }
}


const addons_display = computed(() => {
  if (search.value == null){
    return addons.value
  }
  else{
    return addons.value.filter((item) => item.name.toLowerCase().includes(search.value.toLowerCase()));
  }
  
  }
  )


onMounted(() => {
  getAddons()

})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
