<template>
  <v-card title="New Product" flat>

    <v-form v-model="valid">
      <v-container>
        <v-row justify="center" align="center">
          <v-spacer cols="4" md="4">

          </v-spacer>
          <v-col cols="4" md="4" style="align-items: center;">
            <v-img v-if="imgurl" :width="300" aspect-ratio="16/9" cover :src="imgurl"></v-img>
          </v-col>
          <v-spacer cols="4" md="4">

          </v-spacer>
        </v-row>

        <v-row justify="center" align="center">
          <v-spacer cols="4" md="4">

          </v-spacer>
          <v-col cols="4" md="4">

            <v-file-input v-model="picture" label="File input" @change="onFileChange"></v-file-input>
          </v-col>
          <v-spacer cols="4" md="4">

          </v-spacer>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="name" :rules="nameRules" label="Name" required></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="price" label="Price" required></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="cost" label="Cost" hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-select class="pa-2 ma-2" v-model="addons_selected" :items="addons" label="Available Addons" chips
              multiple></v-select>
          </v-col>


        </v-row>
      </v-container>
    </v-form>
    <v-btn style="margin-bottom: 10px;" @click="submit()">
      Create
    </v-btn>
  </v-card>

</template>


<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()

const picture = ref()
const name = ref()
const valid = ref()
const nameRules = ref([])
const cost = ref()
const price = ref()
const imgurl = ref()
const addons_selected = ref([])
const addons = ref([])

let addons_dict = new Map()

function getAddons() {
  let token = localStorage.getItem('token');
  axios.get('/addon?skip=0&limit=100',
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      for (var value in response.data) {
        addons.value.push(response.data[value]['name'])
        let curr_key = response.data[value]['name']
        let curr_value = response.data[value]['id']
        addons_dict.set(curr_key, curr_value);

      }
    })
    .catch((error) => {
      console.error(error);
    })
}


function init() {

  valid.value = false
  name.value = null
  nameRules.value = [
    value => {
      if (value) return true
      return 'Name is required.'
    }
  ]
  price.value = null
  cost.value = null
}

function onFileChange(e) {
  const file = e.target.files[0];
  imgurl.value = URL.createObjectURL(file);
}


const submit = async () => {
  if (valid.value === true) {

    let token = localStorage.getItem('token');
    let addons_arr = []

    for (var x in addons_selected.value) {
      addons_arr.push(addons_dict.get(addons_selected.value[x]))
    }

    let data = {
      'name': name.value,
      'price': price.value,
      'cost': cost.value,
      'addon_ids': addons_arr,

    }

    try {
      let response = await axios.post('/product',
        data, {
        headers: {
          'token': token,
        }
      })

      if (picture.value != null) {
      let product_id = response.data.id
      var formData = new FormData();

      formData.append("file", picture.value);

      await axios.post(`/product/${product_id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': token,
        }
      })
      
    }
    router.push('/product')
    } catch (error) {
      console.log(error)
    }
    
  }
};

// function submit() {
//   if (valid.value === true) {




//     axios.post('/product',
//       data, {
//       headers: {
//         'token': token,
//         'username': username,
//       }
//     })
//       .then((response) => {
//         let product_id = response.data.id

//         var formData = new FormData();
//         console.log(picture.value)
//         if (picture.value != null) {
//           formData.append("file", picture.value);
//           axios.post(`/product/${product_id}/image`, formData, {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//               'token': token,
//               'username': username,
//             }

//           }).then(() =>{
//             if (response.status == 200) {
//           router.push('/product')
//         }
//           })
//         }

//         if (response.status == 200) {
//           router.push('/product')
//         }

//       })
//       .catch((error) => {
//         console.error(error);
//         // logout()
//       })

//   }
// }

onMounted(() => {
  init()
  getAddons()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
../../router