<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props: activatorProps }">

      <v-card :title="product.name" flat>

        <v-form v-model="valid">
          <v-container>
            <v-row justify="center" align="center">
              <v-spacer cols="4" md="4">

              </v-spacer>
              <v-col cols="4" md="4" style="align-items: center;">
                <v-img :width="300" aspect-ratio="16/9" cover :src="product.s3_link"></v-img>
              </v-col>
              <v-spacer cols="4" md="4">

              </v-spacer>
            </v-row>

            <v-row justify="center" align="center">
              <v-spacer cols="4" md="4">

              </v-spacer>
              <v-col cols="4" md="4">

                <v-file-input v-model="picture" label="File input" @change="onFileChange"></v-file-input>
              </v-col>
              <v-spacer cols="4" md="4">

              </v-spacer>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" md="12">
                <v-text-field hide-details class="pa-2 ma-2" v-model="product.name" :rules="nameRules" label="Name" required></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field hide-details class="pa-2 ma-2" v-model="product.price" label="Price" required></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field hide-details class="pa-2 ma-2" v-model="product.cost" label="Cost"></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-select class="pa-2 ma-2" v-model="addons_selected" :items="addons" label="Available Addons" chips
                  multiple></v-select>
              </v-col>


            </v-row>
          </v-container>
        </v-form>
      </v-card>



      <div style="display:flex; flex-direction:row; justify-content:center">
        <div style="margin: 0px 20px 0px 20px">
          <v-btn v-bind="activatorProps" @click="submit()" color="surface-variant" text="Update" variant="flat"></v-btn>

        </div>
        <div style="margin:0px 20px 0px 20px">
          <v-btn v-bind="activatorProps" @click="delete_product()" color="red" text="Delete" variant="flat"></v-btn>
        </div>
      </div>





    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Update">
        <v-card-text>
          Data Updated Successfully
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Close" @click="isActive.value = false; router.push('/product')"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const product = ref({})
const picture = ref()

const route = useRoute()
const router = useRouter()
const productId = computed(() => route.params.id)

const addons_selected = ref([])
const addons = ref([])

let addons_dict = new Map()
let addons_dict_flip = new Map()
let token = localStorage.getItem('token');

const valid = ref()
const nameRules = ref([])

function delete_product() {
  let data = {
    'id': product.value.id,
    'name': product.value.name,
    'price': product.value.price,
    'cost': product.value.cost,
    'addon_ids' : product.value.addon_ids,
    'is_deleted': true
  }


  axios.patch(`/product/${product.value.id}`,
    data, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )

}

function getAddons() {
  let token = localStorage.getItem('token');
  axios.get('/addon?skip=0&limit=100',
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      // console.log(response.data)
      for (var value in response.data) {
        addons.value.push(response.data[value]['name'])
        let curr_key = response.data[value]['name']
        let curr_value = response.data[value]['id']
        addons_dict.set(curr_key, curr_value);
        addons_dict_flip.set( curr_value,curr_key,);

      }
      for (var addons_temp in product.value.addon_ids){
        addons_selected.value.push(addons_dict_flip.get(product.value.addon_ids[addons_temp]))

      }

    })
    .catch((error) => {
      console.error(error);
    })


}


function init() {

  valid.value = false

  nameRules.value = [
    value => {
      if (value) return true

      return 'Name is required.'
    }
  ]



}

function getProduct() {
  axios.get(`/product/${productId.value}`,
    {
      headers: {
        'token': token
      }
    })
    .then((response) => {
      product.value = response.data;
      getAddons()
    })
    .catch((error) => {
      console.error(error);
    })
  
}

function onFileChange(e) {
  const file = e.target.files[0];

  product.value.s3_link = URL.createObjectURL(file);

  let product_id = product.value.id

  var formData = new FormData();

  formData.append("file", picture.value);
  axios.post(`/product/${product_id}/image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'token': token,
    }}
  )



}

function submit() {
  let addons_arr = []
    for (var x in addons_selected.value) {
      addons_arr.push(addons_dict.get(addons_selected.value[x]))
    }

  let data = {
    'name': product.value.name,
    'price': product.value.price,
    'cost': product.value.cost,
    'addon_ids': addons_arr,
  }

  axios.patch(`/product/${product.value.id}`,
    data, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )
}

onMounted(() => {
  getProduct()
  init()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>