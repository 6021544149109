<template>
  <v-card
    title="New Addon"
    flat
  >
  
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>
          </v-col>
          
          <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="price"
            label="Price"
            required
          ></v-text-field>
        </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="cost"
              label="Cost"
              hide-details
            ></v-text-field>
          </v-col>


        </v-row>
      </v-container>
    </v-form>
    <v-btn  style="margin-bottom: 10px;" @click="submit()">
      Create
    </v-btn>
  </v-card>

</template>


<script setup>
import { ref , onMounted} from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()

const name = ref()
const valid = ref()
const nameRules = ref([])
const cost = ref()
const price = ref()

function init(){

  valid.value = false
  name.value = null
  price.value = null
  cost.value = null

  nameRules.value = [
    value => {
      if (value) return true

      return 'Name is required.'
    }
  ]

}

function submit(){
  if (valid.value === true){
    let token = localStorage.getItem('token');
    let data = {
      'name':name.value,
      'price':price.value,
      'cost': cost.value,
      'is_deleted': false
      
    }

    axios.post('/addon', 
    data,{ 
    headers: { 
      'token': token,
      } })
      .then((response) => {
    console.log(response)

    if(response.status == 200){
      router.push('/addon')
    }

  })
      .catch((error) => {
        console.error(error);
      })
    
  }
}

onMounted(() => {
  init()

})


</script>

<style>

.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td{
  text-align: left;
}
</style>
../../router