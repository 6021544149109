<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props: activatorProps }">

      <v-card flat>

        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col cols="1">
                <v-sheet class="ma-1">
                  <v-btn icon="mdi-keyboard-backspace" variant="plain" to="/addon">
                  </v-btn>
                </v-sheet>
              </v-col>
              <v-col>
                <v-sheet class="ma-1">
                  Add on
                </v-sheet>
              </v-col>
              <v-col cols="1">
                <v-sheet class="ma-1">

                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="addon.name" :rules="nameRules" label="Name" required></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field v-model="addon.price" :rules="PhoneNumberRules" label="Price" required></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field v-model="addon.cost" label="Cost" hide-details></v-text-field>
              </v-col>


            </v-row>
          </v-container>
        </v-form>

      </v-card>



      <div style="display:flex; flex-direction:row; justify-content:center">
        <div style="margin: 0px 20px 0px 20px">
          <v-btn v-bind="activatorProps" @click="submit()" color="surface-variant" text="Update" variant="flat"></v-btn>

        </div>
        <div style="margin:0px 20px 0px 20px">
          <v-btn v-bind="activatorProps" @click="delete_addon()" color="red" text="Delete" variant="flat"></v-btn>
        </div>
      </div>





    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Update">
        <v-card-text>
          Data Updated Successfully
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Close" @click="isActive.value = false; router.push('/addon')"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>


<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const addon = ref({})

const route = useRoute()
const router = useRouter()
const addonId = computed(() => route.params.id)


let token = localStorage.getItem('token');

const valid = ref()
const nameRules = ref([])

function delete_addon() {
  let data = {
    'name': addon.value.name,
    'price': addon.value.price,
    'cost': addon.value.cost,
    'is_deleted': true
  }


  axios.patch(`/addon/${addon.value.id}`,
    data, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )

}

function init() {

  valid.value = false

  nameRules.value = [
    value => {
      if (value) return true

      return 'Name is required.'
    }
  ]

}

const getAddons = async () => {

  try {
    let response = await axios.get(`/addon/${addonId.value}`,
      {
        headers: {
          'token': token
        }
      })
    addon.value = response.data
  } catch (error) {
    console.log(error)
  }
}


function submit() {
  let data = {
    'name': addon.value.name,
    'price': addon.value.price,
    'cost': addon.value.cost,
  }

  axios.patch(`/addon/${addon.value.id}`,
    data, {
    headers: {
      'token': token,
    }
  })
    .then((response) => {
      console.log(response)
    }

    )
}

onMounted(() => {
  getAddons()
  init()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>