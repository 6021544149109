<template>


    <!-- <v-img class="mx-auto my-6" max-width="228"
      src="https://cdn.vuetifyjs.com/docs/images/logos/vuetify-logo-v3-slim-text-light.svg"></v-img> -->

    <v-container>
      <v-row no-gutters style="height: 10vh">
        <v-col>

        </v-col>
      </v-row>
<v-row>
  <v-col>
    <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
      <div class="text-subtitle-1 text-medium-emphasis">Account</div>

      <v-text-field density="compact" placeholder="Email address" prepend-inner-icon="mdi-email-outline"
        variant="outlined" v-model="currentCreds.email"></v-text-field>

      <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
        Password

        <a class="text-caption text-decoration-none text-blue" href="#" rel="noopener noreferrer" target="_blank">
          Forgot login password?</a>
      </div>

      <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
        density="compact" placeholder="Enter your password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
        @click:append-inner="visible = !visible" v-model="currentCreds.password"></v-text-field>

      <v-btn @click="login()" class="mb-8" color="blue" size="large" variant="tonal" block>
        Log In
      </v-btn>
      <!-- <button @click="login()" type="button" class="btn btn-info">Log In</button> -->
      <div v-if="apiResponse === 'Wrong Password'" style="color: red;">Wrong Email Or Password</div>
    </v-card>
  </v-col>
</v-row>
    </v-container>
    

</template>


<script setup>
import axios from 'axios';
import router from '../router';
import { ref } from 'vue';

const currentCreds = ref({})
const visible = ref()
visible.value = false
let apiResponse = ref()

function login() {

  let Creds = {}
  Creds = {

    "email": currentCreds.value.email,
    "password": currentCreds.value.password,

  }

  axios.post(`/login`, Creds)
    .then((response) => {
      
      if (response.status == 200) {
        apiResponse.value = "Correct Password"
        localStorage.setItem('token', response.data['token'])

        if (response.data.user_role == 'admin'){
          router.push('/admin/home')
        }
        else if(response.data.user_role == 'user'){
          router.push('/home')
        }
      }

    })
    .catch(function (error) {
      console.log(error.toJSON());
      apiResponse.value = "Wrong Password"
    })

}


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 25px;
  margin-right: 25px;
  margin-left: 25px;
  padding: 10px 25px 25px 25px;
}

.login-box {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  margin-inline: auto;
  justify-content: center;
  align-items: center;
}
</style>
