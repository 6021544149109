<template>
  <v-card title="Product" flat>
    <template v-slot:text>
      <v-container>
        <v-row no-gutters>
          <v-col>
            <v-sheet class="ma-1">
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
                hide-details single-line></v-text-field>
            </v-sheet>
          </v-col>
          <v-col cols="1">
            <v-sheet class="ma-1">
              <v-btn icon="mdi-plus-box" variant="tonal" to="/product/create" color="blue">
              </v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-container>
      <v-row no-gutters>
        <v-col v-for="product in product_display" :key="product.id" cols="12" sm="4">
          <v-card class="ma-2 pa-2 bg-light-blue-lighten-5" max-width="250" max-height="300px"
            :to="{ name: 'product-individual', params: { id: product.id } }">

            <div >
              <v-img   :width="250" :height='230' :src="product.s3_link"></v-img>
              
            </div>
            <v-card-actions class="justify-center" style="font-size:20px" >
              {{ product.name }}
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-card>

</template>


<script setup>
import { ref, onMounted,  computed } from 'vue';
import axios from 'axios';

const products = ref([])
const search = ref()

const getProducts = async () => {

    let token = localStorage.getItem('token');

    try {
      let response = await axios.get('/product?skip=0&limit=100',
    {
      headers: {
        'token': token
      }
    })
    products.value = response.data
    } catch (error) {
      console.log(error)
    }
    
}

const product_display = computed(() => {
  if (search.value == null){
    return products.value
  }
  else{
    return products.value.filter((item) => item.name.toLowerCase().includes(search.value.toLowerCase()));
  }
  }
  )

onMounted(() => {
  getProducts()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
