<template>
  <v-card title="Order" flat>
    <template v-slot:text>
      <v-container>
        <v-row no-gutters style="height: 10vh">
          <v-col cols="1">
            <v-btn @click="backToPrevPage()" color="gray" prepend-icon="mdi-keyboard-backspace" variant="flat">
              Back</v-btn>
          </v-col>
          <v-spacer>

          </v-spacer>
        </v-row>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col cols="10">

                <v-card id="to_download">

                  <v-container class="pa-3">
                    <v-row justify="space-between" style="height: 15vh">
                      <v-col style="text-align:left; font-size:20px" tag="strong">
                        {{ user.company_name }}
                      </v-col>
                      <v-col style="text-align:right;">
                        <v-card-text style="font-size:20px" tag="strong">
                          Receipt
                        </v-card-text>

                      </v-col>
                    </v-row>
                    <v-row>

                    </v-row>
                    <v-row>
                      <v-col>

                        <v-container>
                          <v-row v-if="customer != undefined">
                            <v-col style="text-align:left;" tag="strong" class="pa-0">
                              Bill to
                            </v-col>
                          </v-row>
                          <v-row v-if="customer != undefined">
                            <v-col style="text-align:left;" class="pa-0">
                              {{ customer.name }}
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>

                      <v-spacer></v-spacer>
                      <v-col style="text-align:right;">
                        <v-container>
                          <v-row>
                            <v-col class="pa-0" align-self="start" tag="strong">
                              Receipt #
                            </v-col>
                            <v-col class="pa-0" align-self="start">
                              {{ orderId }}
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col class="pa-0" align-self="start" tag="strong">
                              Receipt Date
                            </v-col>
                            <v-col class="pa-0" align-self="start">
                              {{ order_created_at }}
                            </v-col>
                          </v-row>


                        </v-container>

                      </v-col>
                    </v-row>

                    <v-container style="margin-right: 30px;">
                      <v-row style="text-align: center;">

                        <v-col cols="1" class='ma-0 border-thin' style="background-color: #D3D3D3;">
                          QTY
                        </v-col>
                        <v-col cols="7" class='ma-0 border-thin' style="background-color: #D3D3D3;">
                          DESCRIPTION
                        </v-col>
                        <v-col cols="2" class='ma-0 border-thin' style="background-color: #D3D3D3;">
                          UNIT PRICE
                        </v-col>
                        <v-col cols="2" class='ma-0 border-thin' style="background-color: #D3D3D3;">
                          AMOUNT
                        </v-col>

                      </v-row>

                      <v-row v-for="(item, index) in order" :key="item">
                        <v-col cols="1" class='ma-0 border-thin' style="text-align: center;">
                          {{ index + 1 }}
                        </v-col>
                        <v-col cols="7" class='ma-0 border-thin' style="text-align: left;">

                          <div>
                            {{ item.name }}
                          </div>
                          <div v-if="item.addon_ids.length != 0" style="color: grey;">
                            Addons:
                          </div>
                          <div>
                            <div v-for="product_addon in item.addon_ids" :key="product_addon"
                              style="text-indent: 15px; color: grey;">
                              {{ product_addon.name }}

                            </div>
                          </div>


                        </v-col>
                        <v-col cols="2" class='ma-0 border-thin' style="text-align: right;">
                          {{ item.discounted_price.toFixed(2) }}
                        </v-col>
                        <v-col cols="2" class='ma-0 border-thin' style="text-align: right;">
                          {{ item.discounted_price.toFixed(2) }}
                        </v-col>
                      </v-row>

                      <v-row justify="end">
                        <v-space>

                        </v-space>
                        <v-col cols="2" class='ma-0'>
                          Subtotal
                        </v-col>
                        <v-col cols="2" class='ma-0 border-thin' style="text-align: right;">
                          {{ order_total }}
                        </v-col>
                      </v-row>
                      <v-row justify="end">
                        <v-space>

                        </v-space>
                        <v-col cols="2" class='ma-0' tag="strong">
                          Total
                        </v-col>
                        <v-col cols="2" class='ma-0 border-thin' tag="strong" style="text-align: right;">
                          RM {{ order_total }}
                        </v-col>
                      </v-row>

                    </v-container>



                  </v-container>


                </v-card>



          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn @click="deleteOrder()" color="red" text="Delete Order" variant="flat"></v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn @click="downloadPDF()" color="surface-variant" text="Download Receipt" variant="flat"></v-btn>
          </v-col>
          <v-spacer v-if="customer != undefined"></v-spacer>
          <v-col v-if="customer != undefined">
            <v-btn @click="sendReceipt()" color="surface-variant" text="Send Receipt" variant="flat"></v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>


    </template>

    <!-- <v-data-table :headers="headers" :items="customers" :search="search" @click:row="handleClick" class="ma-2 pa-2"></v-data-table> -->
  </v-card>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

import { useRoute, useRouter } from 'vue-router';

import html2pdf from 'html2pdf.js'

const router = useRouter()
const route = useRoute()
const order = ref()
const order_created_at = ref()
const customer = ref({})
const order_total = ref()

const token = ref()
const user = ref({})
const orderId = computed(() => route.params.order_id)

token.value = localStorage.getItem('token');

function downloadPDF(){
  var element = document.getElementById('to_download');
  html2pdf().from(element).save(`Receipt_${customer.value.name}_${orderId.value}.pdf`);
}



function getOrderInfo() {
  axios.get(`/order/${orderId.value}`,
    {
      headers: {
        'token': token.value,
      }
    })
    .then((response) => {
      customer.value = response.data.customer
      order.value = response.data.products
      order_total.value = 0
      for (let row_index in response.data.products) {
        let current_row = response.data.products[row_index]
        order_total.value = order_total.value + current_row['discounted_price']
      }
      order_created_at.value = `${response.data.created_at.split("-")[2]}/${response.data.created_at.split("-")[1]}/${response.data.created_at.split("-")[0]}`
      order_total.value = order_total.value.toFixed(2)
      console.log(order.value)
    })
    .catch((error) => {
      console.error(error);
    })
}


function backToPrevPage() {
  router.go(-1)
}

function deleteOrder() {
  axios.delete(`/order/${orderId.value}`,
    {
      headers: {
        'token': token.value,
      }
    })
    .then(router.push(`/order`))
}

const getUserProfile = async () => {

let token = localStorage.getItem('token');

try {
  let response = await axios.get('/admin/token',
    {
      headers: {
        'token': token
      }
    })
  user.value = response.data

} catch (error) {
  console.log(error)
}

}

onMounted(() => {
  getOrderInfo()
  getUserProfile()
})


</script>


<style></style>
