<template>
  <v-card title="New Users" flat>

    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="user.email" label="Email" required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="user.password" label="Password" required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="user.phone_number" label="Phone Number" required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="user.company_name" label="Company Name" required></v-text-field>
          </v-col>


        </v-row>
      </v-container>
    </v-form>
    <v-btn style="margin-bottom: 10px;" @click="submit()">
      Create
    </v-btn>
  </v-card>

</template>


<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()

const user = ref({})

user.value.role = 'user'
user.value.status ='active'

function submit() {

    let token = localStorage.getItem('token');
    let data = user.value
    axios.post('/admin/user',
      data, {
      headers: {
        'token': token,
      }
    })
      .then((response) => {
        console.log(response)

        if (response.status == 200) {
          router.push('/admin/home')
        }

      })
      .catch((error) => {
        console.error(error);
        // logout()
      })

  
}




</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
../../router