<template>
  <v-card title="New Inventory" flat>

    <v-form v-model="valid">
      <v-container>
        <v-row justify="center" align="center">
          <v-spacer cols="4" md="4">

          </v-spacer>
          <v-col cols="4" md="4" style="align-items: center;">
            <v-img v-if="imgurl" :width="300" aspect-ratio="16/9" cover :src="imgurl"></v-img>
          </v-col>
          <v-spacer cols="4" md="4">

          </v-spacer>
        </v-row>

        <v-row justify="center" align="center">
          <v-spacer cols="4" md="4">

          </v-spacer>
          <v-col cols="4" md="4">

            <v-file-input v-model="picture" label="File input" @change="onFileChange"></v-file-input>
          </v-col>
          <v-spacer cols="4" md="4">

          </v-spacer>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="inventory.name" label="Name" required hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="inventory.price" label="Price" required hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="inventory.cost" label="Cost" hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="inventory.quantity" label="Quantity" hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field class="pa-2 ma-2" v-model="inventory.quantity_to_alert" label="Quantity To Alert" hide-details></v-text-field>
          </v-col>



        </v-row>
      </v-container>
    </v-form>
    <v-btn style="margin-bottom: 10px;" @click="submit()">
      Create
    </v-btn>
  </v-card>

</template>


<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()

const picture = ref()
const inventory = ref({})

const valid = ref()
const imgurl = ref()




function init() {
  inventory.value = {}

}

function onFileChange(e) {
  const file = e.target.files[0];
  imgurl.value = URL.createObjectURL(file);
}


const submit = async () => {
  if (valid.value === true) {

    let token = localStorage.getItem('token');

    let data = {
      'name': inventory.value.name,
      'price': inventory.value.price,
      'cost': inventory.value.cost,
      'quantity': inventory.value.quantity,
      'quantity_to_alert':inventory.value.quantity_to_alert
    }

    try {
      let response = await axios.post('/inventory',
        data, {
        headers: {
          'token': token,
        }
      })

      if (picture.value != null) {
      let inventory_id = response.data.id
      var formData = new FormData();

      formData.append("file", picture.value);

      await axios.post(`/inventory/${inventory_id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': token,
        }
      })
      
    }
    router.push('/inventory')
    } catch (error) {
      console.log(error)
    }
    
  }
};

// function submit() {
//   if (valid.value === true) {




//     axios.post('/product',
//       data, {
//       headers: {
//         'token': token,
//         'username': username,
//       }
//     })
//       .then((response) => {
//         let product_id = response.data.id

//         var formData = new FormData();
//         console.log(picture.value)
//         if (picture.value != null) {
//           formData.append("file", picture.value);
//           axios.post(`/product/${product_id}/image`, formData, {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//               'token': token,
//               'username': username,
//             }

//           }).then(() =>{
//             if (response.status == 200) {
//           router.push('/product')
//         }
//           })
//         }

//         if (response.status == 200) {
//           router.push('/product')
//         }

//       })
//       .catch((error) => {
//         console.error(error);
//         // logout()
//       })

//   }
// }

onMounted(() => {
  init()
})


</script>

<style>
.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td {
  text-align: left;
}
</style>
../../router