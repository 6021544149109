<template>
  <v-card title="Orders" flat>
    <template v-slot:text>
      <v-container>
        <v-row no-gutters>
          <v-col>
            <v-sheet class="ma-1">
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
                hide-details single-line></v-text-field>
            </v-sheet>
          </v-col>
          <v-col cols="1">
            <v-sheet class="ma-1">
              <v-btn icon="mdi-plus-box" variant="tonal" to="/order/create" color="blue">
              </v-btn>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-data-table :headers="headers" :items="orders" :search="search" @click:row="handleClick" class="ma-2 pa-2"></v-data-table>
          </v-col>
        </v-row>
      </v-container>

      
    </template>

    <!-- <v-data-table :headers="headers" :items="customers" :search="search" @click:row="handleClick" class="ma-2 pa-2"></v-data-table> -->
  </v-card>

</template>


<script setup>
import { ref, onMounted} from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()
const orders = ref([])
const search = ref()
const headers = ref([])


let token = localStorage.getItem('token');

function handleClick(event, row) {
  router.push(`/order/${row.item.id}`)
}

function init(){
  search.value = ''
  headers.value = [
          {
            align: 'start',
            key: 'created_at',
            sortable: true,
            title: 'Order Date',
          },
          { key: 'id', title: 'Order Id' },
          { key: 'customer_name', title: 'Customer Name' },
          { key: 'products', title: '# Products' },
          { key: 'discounted_price', title: 'Total Amount (RM)' },

        ]
}


function getOrders() {
  axios.get('/order?skip=0&limit=100', 
  { headers: { 
    'token': token,
    } })
    .then((response) => {
      for (let row in response.data){
        let temp_dict = {}
        temp_dict = response.data[row]
        temp_dict['created_at'] = `${temp_dict['created_at'].split("-")[2]}-${temp_dict['created_at'].split("-")[1]}-${temp_dict['created_at'].split("-")[0]}`
        orders.value.push(temp_dict)
      }
    })
    .catch((error) => {
      console.error(error);
    })
}



onMounted(() => {
  init()
  getOrders()

})


</script>

<style>

.flex-container-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-right: 25px;
  margin-left: 25px;
}

.flex-container-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
}

.v-data-table__td{
  text-align: left;
}
</style>
../../router