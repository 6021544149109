import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios';


import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ChangePasswordView from '../views/ChangePasswordView.vue'

import AdminView from '../views/Admin/AdminView.vue'
import AdminIndividualView from '../views/Admin/AdminIndividualView.vue'
import AdminCreateView from '../views/Admin/AdminCreateView.vue'

import CustomerView from '../views/Customer/CustomerView.vue'
import CustomerIndividualView from '../views/Customer/CustomerIndividualView.vue'
import CustomerIndividualEditView from '../views/Customer/CustomerIndividualEditView.vue'
import CustomerCreateView from '../views/Customer/CustomerCreateView.vue'

import AddonView from '../views/Addon/AddonView.vue'
import AddonIndividualView from '../views/Addon/AddonIndividualView.vue'
import AddonCreateView from '../views/Addon/AddonCreateView.vue'

import ProductView from '../views/Product/ProductView.vue'
import ProductIndividualView from '../views/Product/ProductIndividualView.vue'
import ProductCreateView from '../views/Product/ProductCreateView.vue'

import OrderView from '../views/Order/OrderView.vue'
import OrderIndividualView from '../views/Order/OrderIndividualView.vue'
import OrderCreateView from '../views/Order/OrderCreateView.vue'

import InventoryView from '../views/Inventory/InventoryView.vue'
import InventoryIndividualView from '../views/Inventory/InventoryIndividualView.vue'
import InventoryCreateView from '../views/Inventory/InventoryCreateView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      hideNavbar: true,
      requiresAuth: false
     }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordView,
  },
  {
    path: '/admin',
    children: [
      {
        path: 'home',
        name: 'admin-home',
        component: AdminView,
      },
      {
        path: "home/:id",
        name: 'admin-individual',
        component: AdminIndividualView
      },
      {
        path: "create",
        name: 'admin-create',
        component: AdminCreateView
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,

  },
  {
    path: '/customer',
    children: [
      {
        path: '',
        name: 'customer',
        component: CustomerView,
      },
      {
        path: ":id",
        name: 'customer-individual',
        component: CustomerIndividualView
      },
      {
        path: ":customer_id/order/:order_id",
        name: 'customer-order-individual',
        component: OrderIndividualView
      },
      {
        path: ":customer_id/edit",
        name: 'customer-individual-edit',
        component: CustomerIndividualEditView
      },
      {
        path: "create",
        name: 'customer-create',
        component: CustomerCreateView
      }
    ]
  },
  {
    path: '/order',
    children: [
      {
        path: '',
        name: 'order',
        component: OrderView,
      },
      {
        path: ":order_id",
        name: 'order-individual',
        component: OrderIndividualView
      },
      {
        path: "create",
        name: 'order-create',
        component: OrderCreateView
      }
    ]
  },
  {
    path: '/addon',
    children: [
      {
        path: '',
        name: 'addon',
        component: AddonView,
      },
      {
        path: ":id",
        name: 'addon-individual',
        component: AddonIndividualView
      },
      {
        path: "create",
        name: 'addon-create',
        component: AddonCreateView
      }
    ]
  },
  {
    path: '/product',
    children: [
      {
        path: '',
        name: 'product',
        component: ProductView,
      },
      {
        path: ":id",
        name: 'product-individual',
        component: ProductIndividualView
      },
      {
        path: "create",
        name: 'product-create',
        component: ProductCreateView
      }
    ]
  },
  {
    path: '/inventory',
    children: [
      {
        path: '',
        name: 'inventory',
        component: InventoryView,
      },
      {
        path: ":id",
        name: 'inventory-individual',
        component: InventoryIndividualView
      },
      {
        path: "create",
        name: 'inventory-create',
        component: InventoryCreateView
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'is-active',
  routes
})


router.beforeEach((to,from,next) => {

  if (to.meta.requiresAdmin == true) {
    let token = localStorage.getItem('token');
    axios.get('/admin/token', 
    { headers: { 
      'token': token,
      } })
      .then((response) => {
        if(response.data.role == 'admin'){
          next();
        }
        else{
          next('/')
        }
      })
  }

  if (to.meta.requiresAuth == undefined) {

    let token = localStorage.getItem('token');
    if (token == null){
      next('/')
    }
    else{

    
    axios.get('/admin/token', 
    { headers: { 
      'token': token,
      } })
      .then(() => {
        next();
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem('token')
        next('/')
      })
    }
  }
  else{
    next()
  }
})


export default router
