<template>

    <v-container>
      <v-row no-gutters style="height: 20vh">

      </v-row>
      <v-row justify="space-around">
        <v-spacer cols="2"></v-spacer>
        <v-col>
          <v-card title="Change Password">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                  density="compact" :rules="[rules.required, rules.min]" placeholder="Password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
                  @click:append-inner="visible = !visible" v-model="currentCreds.password" hint="At least 8 characters"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :append-inner-icon="visible_2 ? 'mdi-eye-off' : 'mdi-eye'" :type="visible_2 ? 'text' : 'password'"
                  density="compact" :rules="[rules.required, rules.match]" placeholder="Confirm Password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
                  @click:append-inner="visible_2 = !visible_2" v-model="currentCreds.password_repeat"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn @click="changePassword()" class="mb-8" color="blue" size="large" variant="tonal" block>
                    Change Password
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-spacer cols="2"></v-spacer>
      </v-row>
    </v-container>



</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()
const rules = ref({})
const currentCreds = ref({})
const visible = ref()
const visible_2 = ref()

visible.value = false
visible_2.value = false

rules.value = {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          match: v => v == currentCreds.value.password || "Password Dont Match"
        }

let token = localStorage.getItem('token');

const changePassword = async () => {
  try {
      let data = await axios.get('/admin/token',
        {headers: {
          'token': token,
        }}
      )
      
      let user_id = data.data.id
      
      let new_password = {"new_password":currentCreds.value.password}

      console.log(new_password)
      await axios.patch(`/admin/user/${user_id}/update-password`,
        new_password, {
          headers: {
          'token': token,
        }
      })

    router.push('/home')
    } catch (error) {
      console.log(error)
    }
}




</script>

<style></style>
