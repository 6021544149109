<template>
  <!-- <v-card title="Home Page / Dashboard" flat>
    <v-container>
      
      <v-row>
        <v-col>
          
          Charts

          <v-container>
            <v-row justify="space-around">
              <v-col cols="1">
                <v-card variant="outlined">
                  reset
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card variant="outlined">
                  filter
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card variant="outlined">
                  filter
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card variant="outlined">
                  filter
                </v-card>
              </v-col>
              
              
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-card variant="outlined">
                  <Doughnut id="my-chart-id"  :data="doughnutChartData" />
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card variant="outlined">
                  <Doughnut id="my-chart-id"  :data="doughnutChartData" />
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card variant="outlined">
                  <Bar id="my-chart-id" :options="chartOptions" :data="barChartData" />
                </v-card>
              </v-col>
            
            </v-row>

          </v-container>
        </v-col>
      </v-row>
    </v-container>

  </v-card> -->
<v-container>
  <v-row no-gutters style="height: 20vh">
  <v-col>

  </v-col>
</v-row>
<v-row>
  <v-col>

    <div style="font-size: 100px;">
      coming soon
    </div>
  </v-col>
</v-row>
</v-container>


</template>

<script setup>
import { ref, onMounted} from 'vue';
import axios from 'axios';
// import { Bar, Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement } from 'chart.js'

const barChartData = ref({})
const barChartOptions = ref({})
const doughnutChartData = ref({})

const orders = ref([])

let token = localStorage.getItem('token');

// function getCustomers(){

// }

// function getProducts(){

// }

// function getAddons(){

// }

// const getInventory = async () => {
// try {
//   let response = await axios.get('/inventory?is_deleted=true&skip=0&limit=100',
//     {
//       headers: {
//         'token': token
//       }
//     })
//   inventory.value = response.data
// } catch (error) {
//   console.log(error)
// }
// }

function getOrders() {
  axios.get('/order?skip=0&limit=100', 
  { headers: { 
    'token': token,
    } })
    .then((response) => {
      for (let row in response.data){
        let temp_dict = {}
        temp_dict = response.data[row]
        temp_dict['created_at'] = `${temp_dict['created_at'].split("-")[2]}-${temp_dict['created_at'].split("-")[1]}-${temp_dict['created_at'].split("-")[0]}`
        orders.value.push(temp_dict)
      }
    })
    .catch((error) => {
      console.error(error);
    })
}

ChartJS.register(ArcElement,Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


barChartData.value =  {
        labels: [ 'January', 'February', 'March' ],
        datasets: [ { data: [40, 20, 12] } ]
      }

barChartOptions.value = {
        responsive: true
      }


doughnutChartData.value = {
  labels: [
    'Red',
    'Blue',
    'Yellow'
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [300, 50, 100],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }]
};


onMounted(() => {
  getOrders()
})


</script>

<style>

</style>
